<div>
  <div style="background: #06150D;">
    <div class="container mx-auto py-2">
      <div class="md:flex justify-between text-sm">
        <div class="text-green-700 text-center md:text-left my-1">
          Copyright &copy; WayneBEcoTours
        </div>
        <div class="text-green-700 text-center md:text-right my-1">
          Powered By <a
            class="text-green-800"
            target="_blank"
            href="https://gibacmw.com">GibacMw</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
