<script>
  export let destination = {
    img: "",
    label: "",
  };
</script>

<div>
  <div class="relative wow zoomIn">
    <div
      class="relative wrapper bg-gray-300"
      style="background-image: url({destination.img});"
    />
    <div class="text-white text-lg text-center py-2 font-bold label">
      {destination.label}
    </div>
  </div>
</div>

<style>
  .wrapper {
    padding-top: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .label {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
</style>
