<div class="bg-green-900 p-4">
  <div class="container mx-auto">
    <div class="sm:flex items-center justify-between py-16 sm:py-24">
      <div class="text-xl sm:text-4xl">
        <div class="text-yellow-500 wow fadeIn">Experience the</div>
        <div class="text-white wow fadeIn">must-see and must-do</div>
        <div class="text-yellow-500 wow fadeIn">activities</div>
      </div>
      <div class="flex justify-center">
        <img
          src="/images/Kuthengo.jpeg"
          alt=""
          class="w-1/2 border-4 shadow-md border-yellow-500 rotate-img my-12 sm:my-0"
        />
      </div>
    </div>
  </div>
</div>

<style>
  .rotate-img {
    transform: rotate(15deg);
  }
</style>
