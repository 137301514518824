<script>
  export let text = "";
  export let colors = ["#000000", "#d97707"];
  export let classes = "";
</script>

<div>
  <div
    class={`w-full flex justify-center font-bold text-3xl md:text-6xl my-8 md:my-12 ${classes} wow slideInUp`}
  >
    {#each text.split(" ") as word, index}
      <div class="px-2" style="color: {colors[index % 2]}">{word}</div>
    {/each}
  </div>
</div>
