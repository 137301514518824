<script>
  import { slide } from "svelte/transition";
  import { quintOut } from "svelte/easing";
</script>

<div id="home" class="bg-green-900 w-full h-screen px-0 shadow-lg">
  <div class="container w-full h-full mx-auto pt-24 md:pt-32">
    <div class="relative w-full h-full">
      <div class="overlay" />
      <div class="landing w-full h-full shadow-xl" />
      <div class="flex items-center h-full p-8 absolute top-0 left-0">
        <div>
          <div
            class="text-4xl sm:text-6xl text-yellow-500 font-bold wow slideInRight"
          >
            Your
          </div>
          <div
            class="text-4xl sm:text-6xl text-white font-bold wow slideInRight"
            data-wow-delay="0.2s"
          >
            Passionate
          </div>
          <div
            class="text-4xl sm:text-6xl text-yellow-500 font-bold wow slideInRight"
            data-wow-delay="0.4s"
          >
            Tour
          </div>
          <div
            class="text-4xl sm:text-6xl text-white font-bold wow slideInRight"
            data-wow-delay="0.6s"
          >
            Partner
          </div>
        </div>
      </div>
      <div class="flex w-full justify-center absolute bottom-0 ">
        <div class="w-12">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 136.372 150.785"
          >
            <path
              id="Icon_feather-hexagon"
              data-name="Icon feather-hexagon"
              d="M130.872,101.278V45.113a14.041,14.041,0,0,0-7.021-12.146L74.707,4.884a14.041,14.041,0,0,0-14.041,0L11.521,32.967A14.041,14.041,0,0,0,4.5,45.113v56.165a14.041,14.041,0,0,0,7.021,12.146l49.145,28.083a14.041,14.041,0,0,0,14.041,0l49.145-28.083a14.041,14.041,0,0,0,7.021-12.146Z"
              transform="translate(0.5 2.197)"
              fill="none"
              stroke="#d97707"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="15"
            />
          </svg>
        </div>
      </div>
      <div class="absolute right-0 top-1/2 z-10">
        <div
          class="border-4 border-yellow-600 text-white text-xs p-2 rotate bg-green-900"
        >
          <a href="mailto:info@waynebecotours.com">info@waynebecotours.com </a>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .rotate {
    transform: rotate(90deg) translateY(-113%);
  }
  .landing {
    background: url("/images/top/cape-maclear.jpeg") no-repeat fixed
      center/cover;
  }
  .overlay {
    background: #13161594;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
