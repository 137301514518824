<script>
  import Nav from "../Nav.svelte";
</script>

<div
  class="flex justify-center fixed top-0 left-0 w-full z-50 py-4 bg-green-900 pt-8"
>
  <div
    class="flex justify-center font-bold uppercase text-xl sxs:text-2xl sm:text-4xl"
  >
    <div class="text-white">Wayne</div>
    <div class="text-yellow-600">B</div>
    <div class="text-white">Eco</div>
    <div class="text-yellow-600">Tours</div>
  </div>
  <Nav />
</div>
