<script>
  import Heading from "../Heading.svelte";
</script>

<div id="whoweare">
  <Heading text="Who We Are" />
  <div class="container mx-auto">
    <div class="text-xl md:text-4xl p-4 md:p-0">
      <span class="text-yellow-600">WAYNE B ECO TOURS</span> is a small tour
      company based in the town of Lilongwe. It's the smallest in town, however
      the sweetest among all the tour companies. We're a Tour company that
      delivers an enthralling experience to our clients. We offer rich and
      diverse cultural heritage, where you will experience a magical journey
      through breath-taking natural landscapes.
      <br /><br />
      Our teams of experienced and knowledgeable guides have in-depth knowledge of
      mountaineering treks packages, African wildlife safaris, City excursions, day
      trips, cultural experience. We ensure that you will have an unforgettable experience
      from beginning to end.
    </div>
  </div>
</div>
