<div>
  <div class="px-4 md:px-8">
    <div class="text-white text-xl md:text-4xl font-bold mb-4">Contact Us</div>
    <input
      type="text"
      class="block mb-1 w-full p-2"
      placeholder="Enter your name"
    />
    <input
      type="email"
      class="block my-1 w-full p-2"
      placeholder="Enter your email"
    />
    <input
      type="text"
      class="block my-1 w-full p-2"
      placeholder="Enter your phone number"
    />
    <textarea
      name=""
      id=""
      cols="30"
      rows="5"
      class="w-full p-2"
      placeholder="Enter message"
    />
    <button type="submit" class="my-1 w-full bg-green-900 text-white py-2">
      SEND MESSAGE
    </button>
  </div>
</div>
