<script>
  import Heading from "../Heading.svelte";
</script>

<div>
  <div class="my-12 md:my-24 relative">
    <div
      class="absolute top-0 bottom-0 left-0 w-3/4 rounded-r-full bg-gray-300 h-full"
    />
    <div class="flex justify-end relative z-10">
      <div class="w-full sm:w-2/3 py-16">
        <div class="bg-green-900 rounded-l-3xl p-8 md:p-16">
          <Heading
            classes="justify-start text-xl md:text-2xl"
            text="Why Book With Us"
            colors={["#ffffff", "#F2BE6D"]}
          />
          <div class="text-white text-lg md:text-2xl">
            <span class="text-yellow-600">WAYNE B ECO TOURS </span>is run by
            local energetic Young Man. All our tour packages are engineered by
            local experienced team of professionals. What we offers to you is
            real aunthentic local experience that we had experienced,
            experiencing and grown up with. We take to you around with
            enthusiasm, Passion and cultured mannered behavior.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
