<script lang="ts">
  import Tailwind from "./Tailwind.svelte";
  import Home from "./pages/Home.svelte";
  import Header from "./components/Header/Header.svelte";
</script>

<Tailwind />
<main>
  <Header />
  <Home />
</main>
