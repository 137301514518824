<script>
  import ContactUsForm from "../ContactUsForm.svelte";
</script>

<div id="contact">
  <div style="background: #062916;">
    <div class="py-8">
      <div class="container mx-auto">
        <div class="md:flex justify-between">
          <div class="w-full md:w-1/2 p-4">
            <img src="/images/wlogo.png" alt="" />
          </div>
          <div class="w-full md:w-1/2 p-0 md:p-4">
            <ContactUsForm />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
