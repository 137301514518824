<script>
  import ClickOutside from "svelte-click-outside";
  import * as animateScroll from "svelte-scrollto";

  let open = false;
  let menu = [
    {
      name: "Home",
      url: "#home",
    },
    {
      name: "Who We Are",
      url: "#whoweare",
    },
    {
      name: "Destinations",
      url: "#destination",
    },
    {
      name: "Tour Partners",
      url: "#partners",
    },
    {
      name: "Contact Us",
      url: "#contact",
    },
  ];
</script>

<div>
  <ClickOutside on:clickoutside={() => (open = false)}>
    <div class="relative">
      <div class="fixed top-8 right-4 md:right-24">
        <div class="w-6 md:w-10 h-6 md:h-10" on:click={() => (open = true)}>
          <svg viewBox="0 0 100 80" width="100%" height="100%">
            <rect fill="#d97707" width="100" height="15" rx="8" />
            <rect fill="#d97707" y="30" width="100" height="15" rx="8" />
            <rect fill="#d97707" y="60" width="100" height="15" rx="8" />
          </svg>
        </div>
      </div>
      <div class="tray bg-green-900 pt-16 md:pt-24 shadow-2xl" class:open>
        <div class="absolute top-2 right-4 md:right-24">
          <div class="w-6 md:w-10 h-6 md:h-10" on:click={() => (open = false)}>
            <svg viewBox="0 0 100 80" width="100%" height="100%">
              <rect fill="#d97707" width="100" y="30" height="30" rx="8" />
            </svg>
          </div>
        </div>
        {#each menu as menuItem}
          <div
            on:click={() => {
              animateScroll.scrollTo({ element: menuItem.url });
              open = false;
            }}
            class="cursor-pointer py-2 border-t border-b border-green-800 px-4 text-left md:text-center text-white text-lg md:text-3xl font-bold"
          >
            {menuItem.name}
          </div>
        {/each}
        <div class="absolute bottom-0 w-full">
          <div class="contact text-left text-xs p-4">
            <!-- <p class="text-gray-300">
              Wayne B Eco Tours
              <br />
              P.O. Box 2307
              <br />
              LILONGWE
            </p> -->
            <br />
            <br />
            <a href="mailto:info@waynebecotours.com" class="text-yellow-600"
              >info@waynebecotours.com</a
            >
          </div>
        </div>
      </div>
    </div>
  </ClickOutside>
</div>

<style>
  .tray {
    position: fixed;
    height: 100vh;
    width: 70%;
    top: 0;
    left: 100%;
    -webkit-transition: all 0.5s cubic-bezier(0.7, 0.3, 0, 1);
    transition: all 0.5s cubic-bezier(0.7, 0.3, 0, 1);
  }
  .tray.open {
    left: 30%;
  }
  @media only screen and (min-width: 720px) {
    .tray {
      width: 50%;
      left: 100%;
    }
    .tray.open {
      left: 50%;
    }
  }
</style>
