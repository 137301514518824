<div>
  <div class="md:flex justify-between text-lg my-12 md:my-24">
    <div class="w-full md:w-1/2 image h-52 md:h-auto" />
    <div class="bg-green-900 text-white w-full md:w-1/2 p-8 md:p-10">
      WAYNE <span class="text-yellow-600">B</span> ECO
      <span class="text-yellow-600">TOURS</span> is ideal for the first time
      Travellers looking to identify and experience the must-see and must-do
      activities that their preferred destination have to Offer.
      <br /><br />
      <!-- Each of our travel guides is rich and informative and includes; -->
    </div>
  </div>
</div>

<style>
  .image {
    background: url("/images/lake.png") no-repeat left / cover;
  }
</style>
