<script>
  import Footer from "../components/Footer.svelte";
  import AboutUs from "../components/home/AboutUs.svelte";
  import BookUsBanner from "../components/home/BookUsBanner.svelte";
  import ContactUs from "../components/home/ContactUs.svelte";
  import Landing from "../components/home/Landing.svelte";
  import Partners from "../components/home/Partners.svelte";
  import TopDestination from "../components/home/TopDestination.svelte";
  import Welcome from "../components/home/Welcome.svelte";
  import WhoWeAre from "../components/home/WhoWeAre.svelte";
</script>

<div class="relative">
  <Landing />
  <Welcome />
  <WhoWeAre />
  <AboutUs />
  <TopDestination />
  <BookUsBanner />
  <!-- <Partners /> -->
  <ContactUs />
  <Footer />
</div>
