<script>
  import DestinationCard from "../DestinationCard.svelte";
  import Heading from "../Heading.svelte";
  const destinations = [
    {
      img: "/images/top/citytour.jpeg",
      label: "CITY DAY TOUR",
    },
    {
      img: "/images/top/cape-maclear.jpeg",
      label: "BEACH RELAXATION TOUR",
    },
    {
      img: "/images/top/AdRock.jpeg",
      label: "ART ROCK AND HISTORICAL TOUR",
    },
    {
      img: "/images/top/culture.jpeg",
      label: "ART FACTS AND CULTURAL REVOLUTION",
    },
  ];
</script>

<div id="destination">
  <Heading text="Top Destinations" />
  <div class="container mx-auto">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      {#each destinations as destination}
        <DestinationCard {destination} />
      {/each}
    </div>
  </div>
</div>
